<template>
    <v-container>
        <v-col cols="12" class="mt-0 mt-md-16 mb-15">
            <h1 class="mb-16">Cookies Policy</h1>
            <p>Harmony is the launchpad to your latest video, article, recipe, tour, store, website, social post, and other content. We connect your audience to wherever you are online, and make your content more discoverable and easier to manage. Our Website (located at https://harmony.fan/) and other digital properties (such as our mobile app and Developer Portal) (together our “Platform”) provides information about us and allows individuals and businesses to sign-up as Harmony Users (to create a personalised, easily-customisable page), as Subscribers (to subscribe to and follow Harmony Users), or as developers (to create functionality that interacts with our Platform) (together, the “Harmony Services“).</p>

            <p>The pages that Harmony Users are able to create using the Harmony Services are referred to in this Privacy Notice as “User Profiles“. User Profiles are accessible by the public.</p>

            <p>For more information about Harmony, please see the “About” section of our Website at https://harmony.fan/about/.</p>

            <p>How does this Privacy Notice apply to me?</p>

            <p>This Privacy Notice only applies to personal information we collect as a controller from:</p>

            <p>visitors to our Platform (“Platform Visitors“);</p>
            <p>individuals, representatives of individuals, or companies that sign up to use our Harmony Services via a paid plan (“Paid Plan Users“) or free plan (“Free Plan Users“), together our “Harmony Users“;</p>
            <p>individuals that sign up to subscribe to and/or follow User Profiles (“Subscribers“);</p>
            <p>individuals that visit and interact with User Profiles (“Profile Visitors“);</p>
            <p>developers that sign up to our Developer Portal in order to build functionality that interacts with the Harmony Services (“Harmony Developers”); and</p>
            <p>individuals who respond to our surveys, marketing materials or participate in trade promotions or competitions that we may run from time to time.</p>

            <p>This Privacy Notice, applies to the processing of personal information by Harmony as a controller. When we talk about Harmony acting as a “controller”, we mean that Harmony determines the purpose and the means of the processing (i.e. we make decisions about how we will handle your personal information). Because of the nature of our services, we can also act as a “processor” on behalf of Harmony Users. This means that, when we are instructed by a Harmony User, we can facilitate processing of Profile Visitors’ and Subscribers’ personal information on behalf of that Harmony User (“Processor Services“). This Privacy Notice does not address Processor Services. If you are a Profile Visitor or Subscriber, and want to know how a Harmony User handles your personal information, please get in touch with the Harmony User directly and/or refer to any privacy notice on the relevant User Profile.</p>

            <p>If you provide us with information about another person (if, for example, you are a representative of an individual), you must provide them with a copy of this Privacy Notice and let that other person know that we use their personal information in the ways set out in this Privacy Notice.</p>

            <p>What personal information do we collect?</p>

            <p>The personal information that we may collect about you broadly falls into the following categories:</p>

            <p>Information you provide voluntarily</p>

            <p>When you sign up to become a Harmony User, a Subscriber, use or interact with our Harmony Services or staff, visit our Platform, visit a User Profile, sign up to our Developer Portal, respond to a survey or participate in a trade promotion we may ask you to provide personal information voluntarily. For example, if you are a Free Plan User we will ask you to provide your email address, name, username, hashed password, vertical (industry to which your account relates) and marketing preferences. If you are a Paid Plan User we will also ask for your full name, billing email address, billing address and payment method in order to facilitate billing. If you are a Subscriber, we will ask you to provide your email address or SMS number.</p>

            <p>To opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails or SMS we may send you or completing our Data Request Form.</p>

            <p>You may also provide us with your personal data when you submit queries or make a report to us (such as an Intellectual Property Report or Counter Notice). For example, we may ask you to provide your name and email address so that we can respond to your queries. If you are making an Intellectual Property Report or Counter Notice, we ask you to provide your name, address, email address, phone number and details regarding the intellectual property rights concerned.</p>

            <p>If you are a Profile Visitor, a User might request that you provide your email address, mobile number, date of birth or age, or other personal information in order to access elements of a User Profile (such as locked content). We may use the results of such access (i.e. successful or unsuccessful access attempts) to produce aggregated statistics for our own internal purposes and to improve the Harmony Services.</p>

            <p>You may also provide personal information voluntarily if you respond to our surveys, marketing materials, or through your participation in trade promotions and competitions we may run from time to time.</p>

            <p>Information that we collect automatically</p>

            <p>When you visit our Platform, use our Harmony Services, interact with a User Profile, respond to a survey or participate in a trade promotion we collect certain information automatically from your device. In some countries, including countries in the European Economic Area and UK, this information may be considered personal information under applicable data protection laws.</p>

            <p>Specifically, the information we collect automatically may include information like your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location), time zone, usage data, diagnostic data and other technical information. We may also collect information about how your device has interacted with our Platform, Harmony Service or User Profiles, including the pages accessed and links clicked.</p>

            <p>Collecting this information enables us to better understand you, where you come from, and what content is of interest to you. We use this information for our internal analytics purposes, to improve the quality and relevance of our Platform and Harmony Services, to provide hints and tips to our Harmony Users and to make recommendations of Harmony Profiles you might be interested in viewing.</p>

            <p>Some of this information may be collected using cookies and similar tracking technology, as explained further under the heading “How do we use cookies and similar tracking technology” below.</p>

            <p>Further, we may undertake automatic scanning of User Profiles and links to determine whether mandatory or default sensitive content warnings should be applied and presented to Profile Visitors who wish to access the relevant User Profile or linked content, and to determine if any content should be removed or any User Profiles should be suspended in line with our Community Standards and/or Terms of Service. Where a User changes their User Profile, we will also notify relevant Subscribers to that User Profile that updates have been made.</p>

            <p>Information that we obtain from third party sources</p>

            <p>From time to time, we may receive personal information about you from third party sources (including from service providers that help us run marketing campaigns or competitions and our partners who help us provide our Harmony Services). In all cases, we will only receive such data where we have checked that these third parties either have your consent or are otherwise legally permitted or required to disclose your personal information to us.</p>

            <p>Children’s data</p>

            <p>Our services are not intended for use by children under the age of 18 (the “Age Limit”). If you are under the Age Limit, please do not use the Harmony Services and do not provide us with your personal information. If you are a parent or guardian and you are aware that an individual (of whom you are a parent or guardian) under the Age Limit has provided us with personal information, please contact us. We will, upon notice or discovery, take all reasonable efforts to erase or destroy any personal information that may have been collected or stored by us about that individual.</p>

            <p>Why do we collect your personal information?</p>

            <p>In general, we will use the information we collect for the purposes described in this Privacy Notice or for purposes that we explain to you at the time we collect your personal information. These include:</p>

            <p>To provide and deliver the Harmony Services and to assess, maintain and improve the performance and functionality of the Harmony Services.</p>
            <p>To ensure the Harmony Services are relevant to you and your device, to notify you about changes to the Harmony Services, and to deliver targeted and/or localised content based on your user data, location and preferences.</p>
            <p>For consumer research and to allow you to participate in surveys or interactive features of the Harmony Services when you choose to do so.</p>
            <p>To provide customer support and to process and respond to a request, complaint or Intellectual Property Report or Counter Notice that you may have made.</p>
            <p>To monitor the usage of the Harmony Services and to detect, prevent and address technical issues.</p>
            <p>To process payments for Paid Plan Users.</p>
            <p>To conduct business planning, reporting, and forecasting.</p>
            <p>To deliver promotional materials, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted out of receiving such information.</p>
            <p>For the administration of our business including for fulfilling and exercising our obligations and rights, exercising or defending legal claims, to comply with our legal obligations and law enforcement requests, and managing the relationship with you.</p>
            <p>To verify your identity and to detect fraud and potential fraud, including fraudulent payments and fraudulent use of the Harmony Services.</p>
            <p>To include Harmony User content as part of our advertising and marketing campaigns to promote Harmony.</p>
            <p>To inform our algorithms so we can deliver the most relevant recommendations to you, including of User Profiles that you may be interested in.</p>

            <p>Legal basis for processing personal information</p>

            <p>Our legal basis for collecting and using personal information described above will depend on the personal information concerned and the specific circumstances in which we collect it.</p>

            <p>However, we will normally collect personal information from you only where we have your consent, where we need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect personal information from you, or may otherwise need the personal information to protect your vital interests or those of another person.</p>

            <p>If we ask you to provide personal information to comply with a legal requirement we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). As indicated above, we require certain personal information to enter into a contract with you as a Harmony User. Without your personal information, we will be unable to provide you with Harmony Services available to Harmony Users.</p>

            <p>If we collect and use your personal information in reliance on our legitimate interests (or those of any third party), this interest will normally be to develop and improve the Harmony Services, to provide additional functionality, to ensure appropriate security or to implement sensitive content warnings and content moderation. We may have other legitimate interests, and if appropriate, we will make clear to you at the relevant time what those legitimate interests are.</p>

            <p>If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the “Contact Us” heading below.</p>

            <p>Who may we disclose your personal information to?</p>

            <p>We may disclose your personal information to the following categories of recipients:</p>

            <p>to our group companies, third party services providers (for example, to support the delivery of, provide functionality on, or help to enhance the security of our Platform or Harmony Services), or who otherwise process personal information for purposes that are described in this Privacy Notice or notified to you when we collect your personal information. A list of our current group companies is available here and a list of our current service providers and partners is available here. We also facilitate third party payment providers and this is set out in more detail below;</p>
            <p>to the extent we introduce social media log-ins in the future, we may provide personal data to the relevant social media provider to facilitate such log-in;</p>
            <p>to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;</p>
            <p>to an actual or potential buyer (and its agents and advisers) in connection with any actual or proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your personal information only for the purposes disclosed in this Privacy Notice; and</p>
            <p>to any other person with your consent to the disclosure.</p>

            <p>In order to facilitate paid products and/or services within the Harmony Service, we use third party payment processors. We will not store or collect your payment card details. That information is provided directly to our third party payment processors whose use of your personal information is governed by their privacy policies and their own terms and conditions. These payment processors adhere to the standards set by payment card industry data security standards (“PCI-DSS”) as managed by the Payment Card Industry Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The payment processors we work with are:</p>

            <p>Stripe (their privacy policy can be viewed at https://stripe.com/us/privacy);</p>

            <p>PayPal (their privacy policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full); and</p>

            <p>Square (their privacy policy can be viewed at https://squareup.com/au/en/legal/general/privacy).</p>

            <p>Disclosure of personal information to other countries</p>

            <p>Your personal information may be transferred to, and processed in, countries other than the country in which you are a resident. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective).</p>

            <p>Specifically, Harmony may transfer personal information to the United States and other countries in which we do business. Harmony may also subcontract certain activities and share your personal information with third parties located outside of Australia (which is where we are headquartered).</p>

            <p>The countries in which these organisations are located will vary, but, in the course of our business operations, we generally disclose personal information to organisations located in Australia, or the United States. Such transfers are made in order to provide the Harmony Services and carry out the activities specified in this Privacy Notice.</p>

            <p>However, we have taken appropriate safeguards to ensure that your personal information will remain protected in accordance with this Privacy Notice and applicable data protection laws. These include entering into data transfer agreements between our group companies and these can be provided on request. We have implemented similar appropriate safeguards with our third party service providers and partners and further details can be provided upon request. No transfer of your personal information will take place to an organisation or another country unless we believe there to be adequate controls in place including the security of your data and other personal information. For more details please see the “How do we secure your personal information” section below.</p>

            <p>How do we use cookies and similar tracking technology?</p>

            <p>We use cookies and similar tracking technology (collectively, “Cookies”) to collect and use personal information about you. For further information about the types of Cookies we use, why, and how you can control Cookies, please see our Cookie Notice.</p>

            <p>How long do we retain your personal information?</p>

            <p>We will retain your personal information for the period necessary to fulfil the purposes outlined in this Privacy Notice and in each case in accordance with applicable legal and regulatory requirements in respect of permitted or required retention periods and limitation periods relevant to legal action.</p>

            <p>How do we secure your personal information?</p>

            <p>We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.</p>

            <p>In addition we limit access to your personal information to employees, agents, contractors and other third parties who have a business need for access. They will only process your personal information on our instructions and they are subject to a duty of confidentiality.</p>

            <p>While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Therefore, we have put in place procedures to deal with any suspected personal information breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

            <p>What are your rights in relation to personal information?</p>

            <p>You have the following data protection rights:</p>

            <p>If you wish to access, correct or update your personal information, you can do so at any time by completing our Data Request Form.</p>

            <p>Depending on the country which you live in and the laws that apply to you, you may also have additional data protection rights. If you are resident in the UK or the European Economic Area, these rights include:</p>

            <p>To request deletion of your personal information. You can do so at any time by completing our Data Request Form.</p>

            <p>To object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information. Again, you can exercise these rights by completing our Data Request Form.</p>

            <p>To opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you or completing our Data Request Form.</p>

            <p>If we have collected and process your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

            <p>The right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority. (Contact details for data protection authorities in the European Economic Area, Switzerland and certain non-European countries (including the US and Canada) are available here and the UK here.)</p>

            <p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>

            <p>Complaints</p>

            <p>We take your privacy concerns seriously. If you have a complaint regarding our handling of your personal information or concerning our privacy practices, you may file a complaint with us using the contact details provided under the “Contact Us” heading below. We will confirm receipt of your complaint and, if we believe necessary, we will open an investigation.</p>

            <p>We may need to contact you to request further details of your complaint. If an investigation has been opened following a complaint made by you, then we will contact you with the result as soon as possible. In the unlikely circumstances we are unable to resolve your complaint to your satisfaction, you may contact the local privacy and data protection authorities in your jurisdiction.</p>

            <p>Changes to This Privacy Notice</p>

            <p>We may update our Privacy Notice from time to time in response to changing legal, technical or business developments. When we update our Privacy Notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Notice changes if and where this is required by applicable data protection laws.</p>

            <p>You can see when this Privacy Notice was last updated by checking the “last updated” date displayed at the top of this Privacy Notice.</p>

            <p>Contact Us</p>

            <p>If you have any questions about this Privacy Notice, our privacy practices or if you would like to make a request about any personal information we may hold about you, including correction of personal information, please contact us:</p>

            <p>By email using: support@harmony.fan</p>

            <p>By visiting this page on our Platform: harmony/help</p>

            <p>If you want to request any information about how we process, store or use your data or anything relating to personal information and privacy laws, please use our Data Request Form.</p>

            <p>The following section provides supplemental information to California residents, whose personal information we have collected. This section applies to the personal information we collected from California residents during the 12 months prior to the effective date of this Privacy Notice, depending on interactions with us.</p>

            <p>1. Collection and Use of Personal Information</p>

            <p>(a) Categories of Personal Information We Have Collected:</p>

            <p>Identifiers such as name, alias, postal address, online identifier (e.g. cookies) and other similar identifiers;</p>
            <p>Personal records such as payment information;</p>
            <p>Characteristics such as age, gender and other demographic data;</p>
            <p>Customer Accounts / Commercial information such as the User Profile, records of products or services purchased, obtained, or considered;</p>
            <p>Online usage information such as Internet and other network activity information, such as, but not limited to, browsing history, search history and information on interactions with the Harmony Services;</p>
            <p>Geolocation data such as country and zip code;</p>
            <p>Sensory information such as recordings of customer service calls; and</p>
            <p>Inferences derived from personal information such as purchase preferences, interests and characteristics.</p>

            <p>There may be additional information that we collect that meets the definition of personal information under California law but is not reflected in a category, in which case we will treat it as personal information as required under California law, but will not include it when we are required to describe our practices by category of personal information.</p>

            <p>(b) Categories of Sources of Personal Information that We Collect:</p>

            <p>Directly from you. For example, when you create an account, make a purchase, browse our Platform, or use the Harmony Services.</p>
            <p>Directly and indirectly from activity on the Platform and/or using the Harmony Services.</p>
            <p>Social networks (to the extent we introduce social media log-ins in the future).</p>
            <p>Service Providers who provide services on our behalf such as those used to fulfill orders, process your payments and requests, verify your information, monitor activity on the Platform and/or using the Harmony Services, provide analysis and analytics, maintain databases, administer and monitor emails and marketing, administer and send mobile messages, serve ads on this and other services, and provide consulting services.</p>

            <p>2. Categories of Personal Information Disclosed</p>

            <p>We may disclose the following categories of personal information to service providers for a business purpose, or, at your direction, with third parties such as sponsors of promotions, sweepstakes, or contests. We have disclosed the following:</p>

            <p>Category of Personal Information Disclosed for a Business Purpose</p>

            <p>Identifiers;</p>
            <p>Personal records;</p>
            <p>Characteristics;</p>
            <p>Customer Accounts / Commercial information;</p>
            <p>Online usage information;</p>
            <p>Geolocation data;</p>
            <p>Sensory information; and</p>
            <p>Inferences derived from personal information.</p>

            <p>Categories of Recipients</p>

            <p>Service Providers</p>
            <p>Group companies</p>
            <p>Governmental authorities and agencies</p>

            <p>We do not sell personal information and we do not believe that our sharing of information would qualify as a sale under California law.</p>

            <p>For the avoidance of doubt, we also do not sell personal information of individuals under 16 years of age.</p>

            <p>3. California Privacy Rights</p>

            <p>(a) Right to Know</p>

            <p>You have the right to request that we disclose to you the following information about personal information we collect from you:</p>

            <p>categories of personal information collected;</p>
            <p>categories of sources of personal information collected;</p>
            <p>the business or commercial purpose for collecting or selling personal information;</p>
            <p>the categories of third parties with whom we share personal information; and</p>
            <p>the specific pieces of personal information we have collected about you over the past 12 months.</p>

            <p>(b) Right to Request Deletion of Personal Information</p>

            <p>You have the right to request the deletion of your personal information collected or maintained by us, subject to certain exceptions permitted by law.</p>

            <p>(c) Right to Opt-Out of Sale of Personal Information</p>

            <p>As mentioned above, we do not sell personal information and we do not believe that our sharing of information would qualify as a sale under California law. Nonetheless, if you are a California resident who has purchased products or services from us, you may submit a request to record your preference to opt out by emailing us at support@harmony.fan</p>

            <p>(d) Right to Non-Discrimination</p>

            <p>You have the right to not be treated in a discriminatory manner for exercising your privacy rights. We do not use the fact that you have exercised or requested to exercise any privacy rights for any purpose other than facilitating a response to your request.</p>

            <p>4. Exercising Your California Privacy Rights</p>

            <p>To exercise your rights, you can contact us:</p>

            <p>By email at support@harmony.fan</p>
            <p>By visiting this page on our website: harmony.fan/help</p>

            <p>If you want to request any information about how we process, store or use your data or anything relating to personal information and privacy laws, please use our Data Request Form.</p>

            <p>Once we receive your request, we are required to verify that you are the person that is the subject of the request. This verification process consists of matching identifying information provided by you with the information we have about you in our records. Upon making a request, you will be asked to provide your name, email address, and request details. A confirmation email will be sent to the email address you provide to begin the process to verify your identity. If we cannot verify your identity, we will not be able to respond to your request.</p>

            <p>You may designate an authorized agent to exercise any of the above privacy rights on your behalf, subject to the agent request requirements under California law.</p>

            <p>Please note, for your safety and security, we will require authorized agents to provide proof of consent and designation of the authorized agent for the purpose of making the request, and will require authorized agents to provide information necessary to verify the identity of the individual who is the subject of the request. We may also require that an individual verify his/her own identity directly with us before we respond to an authorized agent’s request.</p>

            <p>We reserve the right to deny requests in certain circumstances, such as where we have a reasonable belief that the request is fraudulent.</p>

            <p>5. Do Not Track</p>

            <p>California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Because there currently isn’t an industry standard for recognizing or honoring DNT signals, we do not respond to them at this time.</p>

            <p>6. California’s Shine the Light law</p>

            <p>We do not share personal information with third parties for their direct marketing purposes absent your consent as defined by California Civil Code Section 1798.83 (“Shine the Light law”). To opt-out of future sharing with third parties for their direct marketing purposes, contact us at support@harmony.fan. Any such request must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are only required to respond to one request per customer each year.</p>

            <p>7. Changes</p>

            <p>We reserve the right to change this Supplemental Information for California Residents from time to time. Changes shall become effective on the date they are posted.</p>

            <p>Harmony Group Companies</p>

            <p>Harmony International Holdings Pty Ltd</p>
            <p>Harmony, Inc.</p>

            <p>Profiles (a “profile visitor”), we grant you a limited, non-exclusive, non-transferable right to view and interact with the Platform via user profiles.</p>

            <p>To the extent allowed by law, we’re not responsible for any opinions, advice, statements, products, services, offers or other content posted by other users on their profiles.</p>

            <p>Your plan</p>

            <p>You can sign up to Harmony on a free or paid plan and cancel at any time.</p>

            <p>Your plan will start when you accept these Terms and continue until you cancel it. If you cancel a paid plan it will ordinarily continue until the end of your current billing cycle and then automatically convert to a free plan.</p>

            <p>To cancel, visit the billing page (https://harmony.fan/account-plan). To the extent permitted by applicable law, payments are non-refundable. But we know that sometimes your requirements may change. So, if you’ve selected a paid plan, but cancel within 72 hours, we may make an exception (please email us at support@harmony.fan).</p>

            <p>However, if you downloaded our app and purchased your plan using your Apple ID or Google Play Store account, you’ll need to cancel by following the instructions provided by:</p>

            <p>Apple at https://support.apple.com/en-au/HT202039; or</p>

            <p>Google at https://support.google.com/googleplay/answer/7018481.</p>

            <p>Please note that Harmony is unable to process refunds in these circumstances. You can request a refund from Apple on the Apple Support webpage or Google by following these instructions.</p>

            <p>If you have cancelled your plan and would like to permanently delete your account, you can do so on your ‘My Account’ page. Remember, if you delete your account, you won’t be able to reactivate it or retrieve any of the content or info you have added to your profile.</p>

            <p>Our fees</p>

            <p>You must pay all subscription fees when they become due. Harmony transaction fees as well as processing fees will be deducted automatically from the proceeds of any transactions you make on your profile.</p>

            <p>Subscription fees — If you’ve chosen a paid plan, you must pay your subscription fees when they fall due. Unless otherwise stated, fees are in USD and are inclusive of transactional taxes (e.g. GST/VAT).</p>

            <p>All subscription fees will be billed in advance on a recurring basis, based on your chosen billing cycle (either monthly or annual). Your paid plan will automatically renew under these Terms (as updated at the time of the renewal) at the end of your billing cycle unless you’ve cancelled your plan.</p>

            <p>You must provide a valid payment method (i.e. credit card) and accurate billing information when you join. In doing so, you authorise us to charge all fees to such payment method. If automatic billing fails, we’ll issue you an online invoice, which you must pay within the time indicated.</p>

            <p>Harmony transaction fees — We may charge a percentage of all transactions made on your profile (e.g. through our “Commerce” or “Support Me” features). These transaction fees will differ depending on your plan and will be set out on our pricing page.</p>

            <p>You agree that if we charge transaction fees, they will be deducted from the proceeds of your transaction prior to receipt. You’re responsible for paying any external fees and taxes (including withholding taxes) if they apply, and income taxes, associated with payments you receive through the Platform.</p>

            <p>Processing fees — We use third party payment processing services (including PayPal, Stripe and Square) to power your transactions on Harmony. These services are subject to a processing fee. Each payment processor charges its own processing fees in accordance with its terms and conditions, which you are responsible for reviewing, accepting and complying with.</p>

            <p>You agree that processing fees will be deducted from the proceeds of your transaction prior to receipt and that Harmony is not responsible for these fees.</p>

            <p>We may change our subscription or transaction fees from time to time, but we’ll always give you prior notice (and we will aim to do so at least 1 month in advance). If you don’t agree to the revised fees, you can cancel your plan before your next billing cycle starts.</p>

            <p>Privacy & data</p>

            <p>We care about the privacy of our users and Profile Visitors.</p>

            <p>Our Privacy Notice explains how we handle your personal data for our own purposes. Whereas our Data Processing Addendum (“DPA”) (which is incorporated into these Terms) sets out your responsibilities (and ours) for respecting the privacy rights of visitors to your profile. In creating your account, you agree that where the DPA applies to you, you have read, understood and agreed to it. To find out how we use cookies, visit our Cookie Notice.</p>

            <p>All data (and any IP rights in data) that we or the Platform create or generate based on your use (or profile visitors’, or other users’ use) of the Platform or content (“Data”) will be owned by us. We may provide Data or visualisations of Data to you as part of the service we offer on the Platform (we call this “Data Analytics”). We make no representations or warranties as to the accuracy or completeness of the Data Analytics, but we’ll try to make it as accurate and complete as we can.</p>

            <p>Your visitors & customers</p>

            <p>You’re responsible for your profile visitors, including customers who purchase goods or services through your Harmony (collectively “End Users”).</p>

            <p>You’re solely responsible for (i) the way in which End Users use and interact with your profile and your content; (ii) complying with all laws in relation to your End Users, and all transactions between you and End Users made through your Harmony (e.g. via our “Commerce” or “Payment lock” features).</p>

            <p>You also agree that any donations made to you via our “Support Me” feature are made voluntarily, without exchange for any good or service. You must use this feature solely to collect donations for yourself (and not to collect funds for charities or other causes).</p>

            <p>Confidentiality</p>

            <p>If we share any confidential information about us or the Platform with you, you must keep it secret and secure.</p>

            <p>From time to time, we may share info with you that is confidential (e.g. we may reveal new and upcoming features to you if you take part in beta testing with us). You must keep this info secret and secure and use reasonable measures to prevent others from accessing it.</p>

            <p>Feedback</p>

            <p>We love hearing your ideas on how we can make Harmony even better! Sometimes, we may make “beta” functionality available to you and seek your feedback.</p>

            <p>Keep in mind that if you share feedback with us, we’re free to use it however we like, without payment to you (or to not use it at all). We may from time to time make certain functionality of the Platform available to you in “beta” (or similar). You acknowledge that we are still evaluating and testing such beta functionality and it may not be as reliable as other parts of the Platform.</p>

            <p>Open Source</p>

            <p>The app contains open source software.</p>

            <p>The app contains open source software. Certain open source software components are subject to their own applicable licence terms, which are available in our Open-Source Content library (accessible under the ‘Legal’ section in the app). To the extent that any such licence terms do not permit the relevant open source component to be licensed to you under these Terms, these Terms will not apply to that component, and your rights and obligations in respect of that component will be governed by the applicable licence terms.</p>

            <p>Liability</p>

            <p>We are not liable for your use of the Platform and you’re responsible for keeping backups of your own content.</p>

            <p>We are not liable for any damages suffered as a result of downloading, installing, or using the Platform, or copying, distributing, or downloading any content from the Platform. You have sole responsibility for the adequate protection and backup of your data, content, and devices used in connection with the Platform.</p>

            <p>You will indemnify us against any loss arising out of a breach by you of these Terms, or a third party claim made against us in relation to your content.</p>

            <p>Neither of us will be liable for any indirect, punitive, special, incidental or consequential damage (including loss of business, revenue, profits, use, privacy, data, goodwill or other economic advantage), whether for breach of contract, in tort (including negligence) or otherwise, even if we know such damage is possible.</p>

            <p>Our liability to you under or in connection with these Terms or the Platform will not exceed the greater of the fees you paid to us at the time the liability arose, or $100.</p>

            <p>Disclaimer</p>

            <p>There’s a few things we disclaim under these Terms.</p>

            <p>Your use of Harmony and any content contained on the Platform is at your sole risk. The Platform is provided on an “AS IS” and “AS AVAILABLE” basis, without warranties of any kind, whether express or implied, including (but not limited to), up-time or availability, or any implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

            <p>Harmony, its affiliates and its licensors do not make any express or implied warranties or representations, including that:</p>

            <p>the Platform will function uninterrupted, securely or be available at any time or place;</p>

            <p>any errors or defects will be corrected;</p>

            <p>the Platform is free of viruses or other harmful components;</p>

            <p>the Platform is effective or the results of using the Platform will meet your needs; or</p>

            <p>any content on the Platform (including any user content) is complete, accurate, reliable, suitable or available for any purpose.</p>

            <p>These Terms apply to the maximum extent permitted by law and nothing in them is intended to exclude, restrict or modify legal rights that you may have, which cannot be excluded, restricted or modified by contract. If these Terms are governed by the Australian Consumer Law, our liability to you for not complying with a consumer guarantee is limited to re-supply of the services or payment.</p>

            <p>Third Party Services</p>

            <p>We may include in the Platform, access to specific functionality or services which are provided by third parties (e.g. a payment portal or online store front).</p>

            <p>Harmony integrates with various third party products and services. Unless we say otherwise, we do not endorse (or make any warranties or representations in relation to) any third party product or service and do not offer refunds for any amounts you’ve paid to a third party.</p>

            <p>Your use of any third party product or service may be subject to separate terms and conditions, which you’re responsible for reviewing, accepting and complying with. If you don’t accept or comply with those third party terms, we may suspend or cancel your account, or limit your access to such services within our Platform.</p>

            <p>App terms</p>

            <p>Using our app involves downloading and installing software to your mobile or tablet device. We may automatically update that software and these Terms will apply to any updates.</p>

            <p>The terms in this section apply where you download and use our app. We grant you a limited, revocable, non-exclusive, non-transferable, worldwide right to download and install the app on 1 device, which you own or control. You must not distribute or make the app available over a network where it could be used by many devices at once. Where we or you cancel your account, you must delete the app from your device.</p>

            <p>Where you download the app from the Apple or Google Play stores (“App Stores”) you also agree that:</p>

            <p>these Terms are made between you and Harmony (and not the App Store). However, if any of these Terms is inconsistent with the App Store terms, the applicable App Store term applies;</p>

            <p>we are solely responsible for the app and its content, any maintenance and support services, product warranties (if we haven’t disclaimed them), product claims and third party claims that the app or your use or possession of it, infringes another’s IP Rights. If you have a complaint about the app, or become aware of any third party claim, please email us at support@harmony.fan (and not the App Store);</p>

            <p>you’re not on a US government restricted parties’ list and you don’t live in a country which is subject to a US government embargo or designated as “terrorist supporting country”; and</p>

            <p>the App Store and its subsidiaries are third party beneficiaries of these Terms, and will have the right to enforce these Terms against you as such a beneficiary.</p>

            <p>Cancelling your account</p>

            <p>If you don’t comply with these Terms, we may suspend or cancel your account or limit the functionality of the Platform, which you have access to (e.g. if you don’t pay your fees on time, we may convert your paid plan to a free plan with less features).</p>

            <p>Depending on what the non-compliance is, we may not use this right to suspend or cancel your account, but if there is repeat non-compliance, or a material non-compliance, we are likely to. If we suspend or cancel your account, we will usually notify you beforehand (but we don’t have to) and you will not receive a refund of any fees paid upfront.</p>

            <p>If you think your account has been cancelled by mistake or you have an issue with these Terms or the Platform, please email support@harmony.fan. Both us and you will use all reasonable efforts to resolve the issue in good faith, and neither of us will bring any legal action on the issue until we have spent at least 1 month trying to work it out.</p>

            <p>Changes</p>

            <p>Harmony is constantly evolving and improving. From time to time we may make changes to the Platform or these Terms. If the change will have a significant negative impact on you, we’ll notify you beforehand.</p>

            <p>We may need to change these Terms from time-to-time to reflect business updates, changes to the Platform (including if we decide to discontinue any functionality, features or part of the Platform), legal or commercial reasons, or otherwise to protect our legitimate interests.</p>

            <p>We can make these changes at any time and it’s your responsibility to check these Terms now and then for any changes. However, if a change will have a material negative effect on you, we’ll use our best efforts to notify you at least 1 month before the change takes effect (e.g. via a notification on the Platform).</p>

            <p>Your continued use of the Platform after any changes to the Terms is taken as your acceptance of the revised Terms. If you don’t agree with the changes, we ask you to stop using Harmony and cancel your account.</p>

            <p>A few other things</p>

            <p>There’s a few more things we need to say before you can use Harmony.</p>

            <p>Laws may apply to you or us as a result of your specific location. If this is the case, and if those laws are inconsistent with any part of these Terms, those laws will take precedence to the extent of the inconsistency.</p>

            <p>These Terms (along with the Privacy Notice and any other policies we link to) make up the entire agreement between you and us in connection with the Platform. No other term is to be included, except where required by law. All implied terms except those implied by statute which cannot be excluded are expressly excluded.</p>

            <p>If any provision of these Terms is invalid under the law of any country, then it will be severed from these Terms in that country and the remainder of these Terms will continue to apply.</p>

            <p>These Terms are governed by the laws of Victoria and the Commonwealth of Australia. Both you and we submit to the exclusive jurisdiction of the courts of those jurisdictions.</p>

            <p>Our failure to insist upon or enforce any provision of these Terms will not be considered a waiver of any provision or right. Neither these Terms, nor the Platform, create a relationship of agent / principal between you and us.</p>

            <p>Cookies Policy</p>

            <p>Last updated: 12 July 2024</p>

            <p>Quick links</p>

            <p>We recommend that you read this Privacy Notice in full to ensure you are fully informed. However, if you only want to access a particular section of this Privacy Notice, then you can click on the relevant link below to jump to that section.</p>

            <p>What does Harmony do?</p>

            <p>How does this Privacy Notice apply to me?</p>

            <p>What personal information do we collect?</p>

            <p>Why do we collect your personal information?</p>

            <p>Legal basis for processing personal information</p>

            <p>Who may we disclose your personal information to?</p>

            <p>Disclosure of personal information to other countries</p>

            <p>How do we use cookies and similar tracking technology?</p>

            <p>How long do we retain your personal information?</p>

            <p>How do we secure your personal information?</p>

            <p>What are your rights in relation to personal information?</p>

            <p>Complaints</p>

            <p>Changes to This Privacy Notice</p>

            <p>Contact us</p>

            <p>Supplemental information for Californian residents</p>

            <p>Harmony group companies</p>

            <p>Harmony service providers</p>

            <p>What does Harmony do?</p>

            <p>Harmony is the launchpad to your latest video, article, recipe, tour, store, website, social post, and other content. We connect your audience to wherever you are online, and make your content more discoverable and easier to manage. Our Website (located at https://harmony.fan/) and other digital properties (such as our mobile app and Developer Portal) (together our “Platform”) provides information about us and allows individuals and businesses to sign up as Harmony Users (to create a personalised, easily-customisable page), as Subscribers (to subscribe to and follow Harmony Users), or as developers (to create functionality that interacts with our Platform) (together, the “Harmony Services“).</p>

            <p>The pages that Harmony Users are able to create using the Harmony Services are referred to in this Privacy Notice as “User Profiles“. User Profiles are accessible by the public.</p>

            <p>We are headquartered in Melbourne, Australia and have offices in Sydney. For more information about Harmony, please see the “About” section of our Website at https://harmony.fan.</p>

            <p>How does this Privacy Notice apply to me?</p>

            <p>This Privacy Notice only applies to personal information we collect as a controller from:</p>

            <p>visitors to our Platform (“Platform Visitors“);</p>

            <p>individuals, representatives of individuals, or companies that sign up to use our Harmony Services via a paid plan (“Paid Plan Users“) or free plan (“Free Plan Users“), together our “Harmony Users“;</p>

            <p>individuals that sign up to subscribe to and/or follow User Profiles (“Subscribers“);</p>

            <p>individuals that visit and interact with User Profiles (“Profile Visitors“);</p>

            <p>developers that sign up to our Developer Portal in order to build functionality that interacts with the Harmony Services (“Harmony Developers”); and</p>

            <p>individuals who respond to our surveys, marketing materials or participate in trade promotions or competitions that we may run from time to time.</p>

            <p>This Privacy Notice applies to the processing of personal information by Harmony as a controller. When we talk about Harmony acting as a “controller”, we mean that Harmony determines the purpose and the means of the processing (i.e. we make decisions about how we will handle your personal information). Because of the nature of our services, we can also act as a “processor” on behalf of Harmony Users. This means that, when we are instructed by a Harmony User, we can facilitate processing of Profile Visitors’ and Subscribers’ personal information on behalf of that Harmony User (“Processor Services“). This Privacy Notice does not address Processor Services. If you are a Profile Visitor or Subscriber, and want to know how a Harmony User handles your personal information, please get in touch with the Harmony User directly and/or refer to any privacy notice on the relevant User Profile.</p>

            <p>If you provide us with information about another person (if, for example, you are a representative of an individual), you must provide them with a copy of this Privacy Notice and let that other person know that we use their personal information in the ways set out in this Privacy Notice.</p>

            <p>What personal information do we collect?</p>

            <p>The personal information that we may collect about you broadly falls into the following categories:</p>

            <p>Information you provide voluntarily</p>

            <p>When you sign up to become a Harmony User, a Subscriber, use or interact with our Harmony Services or staff, visit our Platform, visit a User Profile, sign up to our Developer Portal, respond to a survey or participate in a trade promotion we may ask you to provide personal information voluntarily. For example, if you are a Free Plan User we will ask you to provide your email address, name, username, hashed password, vertical (industry to which your account relates) and marketing preferences. If you are a Paid Plan User we will also ask for your full name, billing email address, billing address and payment method in order to facilitate billing. If you are a Subscriber, we will ask you to provide your email address or SMS number.</p>

            <p>To opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails or SMS we may send you or completing our Data Request Form.</p>

            <p>You may also provide us with your personal data when you submit queries or make a report to us (such as an Intellectual Property Report or Counter Notice). For example, we may ask you to provide your name and email address so that we can respond to your queries. If you are making an Intellectual Property Report or Counter Notice, we ask you to provide your name, address, email address, phone number and details regarding the intellectual property rights concerned.</p>

            <p>If you are a Profile Visitor, a User might request that you provide your email address, mobile number, date of birth or age, or other personal information in order to access elements of a User Profile (such as locked content). We may use the results of such access (i.e. successful or unsuccessful access attempts) to produce aggregated statistics for our own internal purposes and to improve the Harmony Services.</p>

            <p>You may also provide personal information voluntarily if you respond to our surveys, marketing materials, or through your participation in trade promotions and competitions we may run from time to time.</p>

            <p>Information that we collect automatically</p>

            <p>When you visit our Platform, use our Harmony Services, interact with a User Profile, respond to a survey or participate in a trade promotion we collect certain information automatically from your device. In some countries, including countries in the European Economic Area and UK, this information may be considered personal information under applicable data protection laws.</p>

            <p>Specifically, the information we collect automatically may include information like your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location), time zone, usage data, diagnostic data and other technical information. We may also collect information about how your device has interacted with our Platform, Harmony Service or User Profiles, including the pages accessed and links clicked.</p>

            <p>Collecting this information enables us to better understand you, where you come from, and what content is of interest to you. We use this information for our internal analytics purposes, to improve the quality and relevance of our Platform and Harmony Services, to provide hints and tips to our Harmony Users and to make recommendations of Harmony Profiles you might be interested in viewing.</p>

            <p>Some of this information may be collected using cookies and similar tracking technology, as explained further under the heading “How do we use cookies and similar tracking technology” below.</p>

            <p>Further, we may undertake automatic scanning of User Profiles and links to determine whether mandatory or default sensitive content warnings should be applied and presented to Profile Visitors who wish to access the relevant User Profile or linked content, and to determine if any content should be removed or any User Profiles should be suspended in line with our Community Standards and/or Terms of Service. Where a User changes their User Profile, we will also notify relevant Subscribers to that User Profile that updates have been made.</p>

            <p>Information that we obtain from third party sources</p>

            <p>From time to time, we may receive personal information about you from third party sources (including from service providers that help us run marketing campaigns or competitions and our partners who help us provide our Harmony Services). In all cases, we will only receive such data where we have checked that these third parties either have your consent or are otherwise legally permitted or required to disclose your personal information to us.</p>

            <p>Children’s data</p>

            <p>Our services are not intended for use by children under the age of 18 (the “Age Limit”). If you are under the Age Limit, please do not use the Harmony Services and do not provide us with your personal information. If you are a parent or guardian and you are aware that an individual (of whom you are a parent or guardian) under the Age Limit has provided us with personal information, please contact us. We will, upon notice or discovery, take all reasonable efforts to erase or destroy any personal information that may have been collected or stored by us about that individual.</p>

            <p>Why do we collect your personal information?</p>

            <p>In general, we will use the information we collect for the purposes described in this Privacy Notice or for purposes that we explain to you at the time we collect your personal information. These include:</p>

            <p>To provide and deliver the Harmony Services and to assess, maintain and improve the performance and functionality of the Harmony Services.</p>

            <p>To ensure the Harmony Services are relevant to you and your device, to notify you about changes to the Harmony Services, and to deliver targeted and/or localised content based on your user data, location and preferences.</p>

            <p>For consumer research and to allow you to participate in surveys or interactive features of the Harmony Services when you choose to do so.</p>

            <p>To provide customer support and to process and respond to a request, complaint or Intellectual Property Report or Counter Notice that you may have made.</p>

            <p>To monitor the usage of the Harmony Services and to detect, prevent and address technical issues.</p>

            <p>To process payments for Paid Plan Users.</p>

            <p>To conduct business planning, reporting, and forecasting.</p>

            <p>To deliver promotional materials, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted out of receiving such information.</p>

            <p>For the administration of our business including for fulfilling and exercising our obligations and rights, exercising or defending legal claims, to comply with our legal obligations and law enforcement requests, and managing the relationship with you.</p>

            <p>To verify your identity and to detect fraud and potential fraud, including fraudulent payments and fraudulent use of the Harmony Services.</p>

            <p>To include Harmony User content as part of our advertising and marketing campaigns to promote Harmony.</p>

            <p>To inform our algorithms so we can deliver the most relevant recommendations to you, including of User Profiles that you may be interested in.</p>

            <p>Legal basis for processing personal information</p>

            <p>Our legal basis for collecting and using personal information described above will depend on the personal information concerned and the specific circumstances in which we collect it.</p>

            <p>However, we will normally collect personal information from you only where we have your consent, where we need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect personal information from you, or may otherwise need the personal information to protect your vital interests or those of another person.</p>

            <p>If we ask you to provide personal information to comply with a legal requirement we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). As indicated above, we require certain personal information to enter into a contract with you as a Harmony User. Without your personal information, we will be unable to provide you with Harmony Services available to Harmony Users.</p>

            <p>If we collect and use your personal information in reliance on our legitimate interests (or those of any third party), this interest will normally be to develop and improve the Harmony Services, to provide additional functionality, to ensure appropriate security or to implement sensitive content warnings and content moderation. We may have other legitimate interests, and if appropriate, we will make clear to you at the relevant time what those legitimate interests are.</p>

            <p>If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the “Contact Us” heading below.</p>

            <p>Who may we disclose your personal information to?</p>

            <p>We may disclose your personal information to the following categories of recipients:</p>

            <p>to our group companies, third party services providers (for example, to support the delivery of, provide functionality on, or help to enhance the security of our Platform or Harmony Services), or who otherwise process personal information for purposes that are described in this Privacy Notice or notified to you when we collect your personal information. A list of our current group companies is available here and a list of our current service providers and partners is available here. We also facilitate third party payment providers and this is set out in more detail below;</p>

            <p>to the extent we introduce social media log-ins in the future, we may provide personal data to the relevant social media provider to facilitate such log-in;</p>

            <p>to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;</p>

            <p>to an actual or potential buyer (and its agents and advisers) in connection with any actual or proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your personal information only for the purposes disclosed in this Privacy Notice; and</p>

            <p>to any other person with your consent to the disclosure.</p>

            <p>In order to facilitate paid products and/or services within the Harmony Service, we use third party payment processors. We will not store or collect your payment card details. That information is provided directly to our third party payment processors whose use of your personal information is governed by their privacy policies and their own terms and conditions. These payment processors adhere to the standards set by payment card industry data security standards (“PCI-DSS”) as managed by the Payment Card Industry Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The payment processors we work with are:</p>

            <p>Stripe (their privacy policy can be viewed at https://stripe.com/us/privacy);</p>

            <p>PayPal (their privacy policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full); and</p>

            <p>Square (their privacy policy can be viewed at https://squareup.com/au/en/legal/general/privacy).</p>

            <p>Disclosure of personal information to other countries</p>

            <p>Your personal information may be transferred to, and processed in, countries other than the country in which you are a resident. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective).</p>

            <p>Specifically, Harmony may transfer personal information to the United States and other countries in which we do business. Harmony may also subcontract certain activities and share your personal information with third parties located outside of Australia (which is where we are headquartered).</p>

            <p>The countries in which these organisations are located will vary, but, in the course of our business operations, we generally disclose personal information to organisations located in Australia, or the United States. Such transfers are made in order to provide the Harmony Services and carry out the activities specified in this Privacy Notice.</p>

            <p>However, we have taken appropriate safeguards to ensure that your personal information will remain protected in accordance with this Privacy Notice and applicable data protection laws. These include entering into data transfer agreements between our group companies and these can be provided on request. We have implemented similar appropriate safeguards with our third party service providers and partners and further details can be provided upon request. No transfer of your personal information will take place to an organisation or another country unless we believe there to be adequate controls in place including the security of your data and other personal information. For more details please see the “How do we secure your personal information” section below.</p>

            <p>How do we use cookies and similar tracking technology?</p>

            <p>We use cookies and similar tracking technology (collectively, “Cookies”) to collect and use personal information about you. For further information about the types of Cookies we use, why, and how you can control Cookies, please see our Cookie Notice.</p>

            <p>How long do we retain your personal information?</p>

            <p>We will retain your personal information for the period necessary to fulfil the purposes outlined in this Privacy Notice and in each case in accordance with applicable legal and regulatory requirements in respect of permitted or required retention periods and limitation periods relevant to legal action.</p>

            <p>How do we secure your personal information?</p>

            <p>We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.</p>

            <p>In addition we limit access to your personal information to employees, agents, contractors and other third parties who have a business need for access. They will only process your personal information on our instructions and they are subject to a duty of confidentiality.</p>

            <p>While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Therefore, we have put in place procedures to deal with any suspected personal information breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

            <p>What are your rights in relation to personal information?</p>

            <p>You have the following data protection rights:</p>

            <p>If you wish to access, correct or update your personal information, you can do so at any time by completing our Data Request Form.</p>

            <p>Depending on the country which you live in and the laws that apply to you, you may also have additional data protection rights. If you are resident in the UK or the European Economic Area, these rights include:</p>

            <p>To request deletion of your personal information. You can do so at any time by completing our Data Request Form.</p>

            <p>To object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information. Again, you can exercise these rights by completing our Data Request Form.</p>

            <p>To opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you or completing our Data Request Form.</p>

            <p>If we have collected and process your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

            <p>The right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority. (Contact details for data protection authorities in the European Economic Area, Switzerland and certain non-European countries (including the US and Canada) are available here and the UK here.)</p>

            <p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>

            <p>Complaints</p>

            <p>We take your privacy concerns seriously. If you have a complaint regarding our handling of your personal information or concerning our privacy practices, you may file a complaint with us using the contact details provided under the “Contact Us” heading below. We will confirm receipt of your complaint and, if we believe necessary, we will open an investigation.</p>

            <p>We may need to contact you to request further details of your complaint. If an investigation has been opened following a complaint made by you, then we will contact you with the result as soon as possible. In the unlikely circumstances we are unable to resolve your complaint to your satisfaction, you may contact the local privacy and data protection authorities in your jurisdiction.</p>

            <p>Changes to This Privacy Notice</p>

            <p>We may update our Privacy Notice from time to time in response to changing legal, technical or business developments. When we update our Privacy notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Notice changes if and where this is required by applicable data protection laws.</p>

            <p>You can see when this Privacy Notice was last updated by checking the “last updated” date displayed at the top of this Privacy Notice.</p>

            <p>Contact Us</p>

            <p>If you have any questions about this Privacy Notice, our privacy practices or if you would like to make a request about any personal information we may hold about you, including correction of personal information, please contact us:</p>

            <p>By email using: support@harmony.fan</p>

            <p>By visiting this page on our Platform: harmony/help</p>

            <p>If you want to request any information about how we process, store or use your data or anything relating to personal information and privacy laws, please use our Data Request Form.</p>

            <p>The following section provides supplemental information to California residents, whose personal information we have collected. This section applies to the personal information we collected from California residents during the 12 months prior to the effective date of this Privacy Notice, depending on interactions with us.</p>

            <p>1. Collection and Use of Personal Information</p>

            <p>(a) Categories of Personal Information We Have Collected:</p>

            <p>Identifiers such as name, alias, postal address, online identifier (e.g. cookies) and other similar identifiers;</p>

            <p>Personal records such as payment information;</p>

            <p>Characteristics such as age, gender and other demographic data;</p>

            <p>Customer Accounts / Commercial information such as the User Profile, records of products or services purchased, obtained, or considered;</p>

            <p>Online usage information such as Internet and other network activity information, such as, but not limited to, browsing history, search history and information on interactions with the Harmony Services;</p>

            <p>Geolocation data such as country and zip code;</p>

            <p>Sensory information such as recordings of customer service calls; and</p>

            <p>Inferences derived from personal information such as purchase preferences, interests and characteristics.</p>

            <p>There may be additional information that we collect that meets the definition of personal information under California law but is not reflected in a category, in which case we will treat it as personal information as required under California law, but will not include it when we are required to describe our practices by category of personal information.</p>

            <p>(b) Categories of Sources of Personal Information that We Collect:</p>

            <p>Directly from you. For example, when you create an account, make a purchase, browse our Platform, or use the Harmony Services.</p>

            <p>Directly and indirectly from activity on the Platform and/or using the Harmony Services.</p>

            <p>Social networks (to the extent we introduce social media log-ins in the future).</p>

            <p>Service Providers who provide services on our behalf such as those used to fulfill orders, process your payments and requests, verify your information, monitor activity on the Platform and/or using the Harmony Services, provide analysis and analytics, maintain databases, administer and monitor emails and marketing, administer and send mobile messages, serve ads on this and other services, and provide consulting services.</p>

            <p>2. Categories of Personal Information Disclosed</p>

            <p>We may disclose the following categories of personal information to service providers for a business purpose, or, at your direction, with third parties such as sponsors of promotions, sweepstakes, or contests. We have disclosed the following:</p>

            <p>Category of Personal Information Disclosed for a Business Purpose</p>

            <p>Identifiers;</p>

            <p>Personal records;</p>

            <p>Characteristics;</p>

            <p>Customer Accounts / Commercial information;</p>

            <p>Online usage information;</p>

            <p>Geolocation data;</p>

            <p>Sensory information; and</p>

            <p>Inferences derived from personal information.</p>

            <p>Categories of Recipients</p>

            <p>Service Providers</p>

            <p>Group companies</p>

            <p>Governmental authorities and agencies</p>

            <p>We do not sell personal information and we do not believe that our sharing of information would qualify as a sale under California law.</p>

            <p>For the avoidance of doubt, we also do not sell personal information of individuals under 16 years of age.</p>

            <p>3. California Privacy Rights</p>

            <p>(a) Right to Know</p>

            <p>You have the right to request that we disclose to you the following information about personal information we collect from you:</p>

            <p>categories of personal information collected;</p>

            <p>categories of sources of personal information collected;</p>

            <p>the business or commercial purpose for collecting or selling personal information;</p>

            <p>the categories of third parties with whom we share personal information; and</p>

            <p>the specific pieces of personal information we have collected about you over the past 12 months.</p>

            <p>(b) Right to Request Deletion of Personal Information</p>

            <p>You have the right to request the deletion of your personal information collected or maintained by us, subject to certain exceptions permitted by law.</p>

            <p>(c) Right to Opt-Out of Sale of Personal Information</p>

            <p>As mentioned above, we do not sell personal information and we do not believe that our sharing of information would qualify as a sale under California law. Nonetheless, if you are a California resident who has purchased products or services from us, you may submit a request to record your preference to opt out by emailing us at support@harmony.fan</p>

            <p>(d) Right to Non-Discrimination</p>

            <p>You have the right to not be treated in a discriminatory manner for exercising your privacy rights. We do not use the fact that you have exercised or requested to exercise any privacy rights for any purpose other than facilitating a response to your request.</p>

            <p>4. Exercising Your California Privacy Rights</p>

            <p>To exercise your rights, you can contact us:</p>

            <p>By email at support@harmony.fan</p>

            <p>By visiting this page on our website: harmony.fan/help</p>

            <p>If you want to request any information about how we process, store or use your data or anything relating to personal information and privacy laws, please use our Data Request Form.</p>

            <p>Once we receive your request, we are required to verify that you are the person that is the subject of the request. This verification process consists of matching identifying information provided by you with the information we have about you in our records. Upon making a request, you will be asked to provide your name, email address, and request details. A confirmation email will be sent to the email address you provide to begin the process to verify your identity. If we cannot verify your identity, we will not be able to respond to your request.</p>

            <p>You may designate an authorized agent to exercise any of the above privacy rights on your behalf, subject to the agent request requirements under California law.</p>

            <p>Please note, for your safety and security, we will require authorized agents to provide proof of consent and designation of the authorized agent for the purpose of making the request, and will require authorized agents to provide information necessary to verify the identity of the individual who is the subject of the request. We may also require that an individual verify his/her own identity directly with us before we respond to an authorized agent’s request.</p>

            <p>We reserve the right to deny requests in certain circumstances, such as where we have a reasonable belief that the request is fraudulent.</p>

            <p>5. Do Not Track</p>

            <p>California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Because there currently isn’t an industry standard for recognizing or honoring DNT signals, we do not respond to them at this time.</p>

            <p>6. California’s Shine the Light law</p>

            <p>We do not share personal information with third parties for their direct marketing purposes absent your consent as defined by California Civil Code Section 1798.83 (“Shine the Light law”). To opt-out of future sharing with third parties for their direct marketing purposes, contact us at support@harmony.fan. Any such request must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are only required to respond to one request per customer each year.</p>

            <p>7. Changes</p>

            <p>We reserve the right to change this Supplemental Information for California Residents from time to time. Changes shall become effective on the date they are posted.</p>

        </v-col>
    </v-container>
</template>

<script>
    export default {
        name: "CookiesPolicy",
        metaInfo: {
            titleTemplate: '%s - Harmony Fan',
            title: 'Política de cookies'
        }
    };
</script>
